import React, { useMemo } from 'react'

import { FreeMode, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './styles.scss'
import useWindowSize from '../../../hooks/useWindowSize'
import { Flex } from '@chakra-ui/react'

// export const media = [
// 	{
// 		id: 2,
// 		title: 'barrons',
// 		desc: '200 Ukrainian Artists Will Raise War Funds With Their Own NFTs',
// 		image: '/images/mainPage/barrons.png',
// 		link: 'https://www.barrons.com/articles/200-ukrainian-artists-to-create-nfts-to-raise-funds-for-war-effort-01646258029',
// 	},
// 	{
// 		id: 4,
// 		title: 'dou',
// 		desc: 'HolyWater в колаборації з українськими митцями запустить продаж NFT-колекції про події в Україні...',
// 		image: '/images/mainPage/dou.png',
// 		link: 'https://dou.ua/forums/topic/36810/',
// 	},
// 	{
// 		id: 4,
// 		title: 'entrepreneur',
// 		desc: '',
// 		image: '/images/mainPage/entrepreneur.png',
// 		link: 'https://www.entrepreneur.com/money-finance/5-ways-to-support-ukrainians-using-blockchain/422144',
// 	},
// 	{
// 		id: 0,
// 		title: 'guardian',
// 		desc: 'Keyboard warriors: Ukraine’s IT army switches to war footing',
// 		image: '/images/mainPage/guardian.png',
// 		link: 'https://www.theguardian.com/world/2022/mar/12/keyboard-warriors-ukraines-it-army-switches-to-war-footing',
// 	},
// 	{
// 		id: 7,
// 		title: 'hustle',
// 		desc: 'People are sending crypto and NFTs to Ukraine',
// 		image: '/images/mainPage/hustle.png',
// 		link: 'https://thehustle.co/03072022-crypto-donations',
// 	},
// 	{
// 		id: 9,
// 		title: 'nftcalendar',
// 		desc: 'NFT Collection by over 200 Ukrainian Artists and Children To Save Ukraine',
// 		image: '/images/mainPage/nftcalendar.png',
// 		link: 'https://nftcalendar.io/event/nft-collection-by-ukrainian-artists-and-children-to-save-ukraine/',
// 	},
// 	{
// 		id: 3,
// 		title: 'nftculture',
// 		desc: 'Over 200 Ukrainian artists unite to create an NFT collection to raise funds for the Ukrainian army in the war...',
// 		image: '/images/mainPage/nftculture.png',
// 		link: 'https://www.nftculture.com/nft-art/nfts-for-ukraine/',
// 	},
// 	{
// 		id: 8,
// 		title: 'telegraf',
// 		desc: '',
// 		image: '/images/mainPage/telegraf.png',
// 		link: 'https://telegraf.design/news/ukrayinskyj-startap-zapuskaye-prodazh-nft-mystetstva-dlya-dopomogy-ukrayinskij-armiyi/',
// 	},
// 	{
// 		id: 6,
// 		title: 'loficial',
// 		desc: 'Український стартап у колаборації з художниками створює NFT-картини, аби зібрати $ 1 мільйон...',
// 		image: '/images/mainPage/loficial.png',
// 		link: 'https://officiel-online.com/all-news/nft-art-for-ukranian-army-help/',
// 	},
// 	{
// 		id: 5,
// 		title: 'ain',
// 		desc: '',
// 		image: '/images/mainPage/ain.png',
// 		link: 'https://ain.ua/2022/10/26/kryptovalyuta-dlya-volonteriv/',
// 	},
// ]
export const media = [
	{
		id: 1,
		title: 'TechCrunch',
		desc: '',
		image: '/images/mainPage/tech_crunch.png',
		link: 'https://techcrunch.com/2024/09/04/short-series-app-my-drama-launching-ai-companions/',
	},
	{
		id: 3,
		title: 'entrepreneur',
		desc: '',
		image: '/images/mainPage/entrepreneur.png',
		link: 'https://www.entrepreneur.com/starting-a-business/how-to-be-a-mindful-leader-who-is-resilient-to-crises/477390',
	},
	{
		id: 6,
		title: 'The Recursive',
		desc: '',
		image: '/images/mainPage/recursive.png',
		link: 'https://therecursive.com/90m-arr-ukrainian-startup-strategies-for-growth-holywater-lessons/',
	},
	{
		id: 1,
		title: 'HackerNoon',
		desc: '',
		image: '/images/mainPage/HackerNoon.png',
		link: 'https://hackernoon.com/our-short-form-series-perform-better-than-netflix-heres-how-we-film-them',
	},
	// {
	// 	id: 6,
	// 	title: 'The Village',
	// 	desc: '',
	// 	image: '/images/mainPage/The_Village.png',
	// 	link: 'https://www.village.com.ua/village/culture/culture-news/353409-znaesh-scho-take-lyubov-vivat-zapuskae-proekt-dlya-avtoriv-u-zhanri-romance-yak-vzyati-uchast',
	// },
	{
		id: 10,
		title: 'ain',
		desc: '',
		image: '/images/mainPage/ain.png',
		link: 'https://ain.ua/2024/08/05/holywater-i-vivat-sukaje-avtoriv/',
	},
	{
		id: 2,
		title: 'Dev',
		desc: '',
		image: '/images/mainPage/dev_ua.png',
		link: 'https://dev.ua/news/holywater-ta-vivat-zapuskaiut-proiekt-pyshy-1722958171',
	},
	{
		id: 4,
		title: 'MC.TODAY',
		desc: '',
		image: '/images/mainPage/MC_today.png',
		link: 'https://mc.today/uk/ukrayintsi-mozhut-napisati-istoriyu-na-osnovi-yakoyi-znimut-serial-seo-holywater-rozpoviv-detali-ta-dav-poradi-uchasnikam/',
	},
	{
		id: 5,
		title: 'SPEKA',
		desc: '',
		image: '/images/mainPage/speka.png',
		link: 'https://speka.media/vivat-ta-holywater-sukayut-novi-ukrayinski-lyubovni-romani-9g25x6',
	},
	// {
	// 	id: 7,
	// 	title: 'BAZILIK',
	// 	desc: '',
	// 	image: '/images/mainPage/bazilik.png',
	// 	link: 'https://bazilik.media/holywater-ta-vivat-prezentuvaly-proiekt-pyshy/',
	// },
]

const FeaturedBy = ({ title, isClickable }) => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	const onClick = link => {
		if (!isClickable) return
		window.open(link, '_blank')
	}
	return (
		<>
			{isMobile ? (
				<>
					{title && <p className="featured-title">Featured by</p>}
					<Swiper
						slidesPerView="auto"
						speed={1500}
						freeMode={true}
						spaceBetween={32}
						loop
						pagination={{ type: 'progressbar' }}
						className="about-slider"
						modules={[FreeMode, Pagination]}
						allowSlidePrev={true}
						allowSlideNext={true}
					>
						{media.map(item => (
							<SwiperSlide key={item.id} style={{ width: 'auto' }}>
								<button
									onClick={() => onClick(item.link)}
									className="media-link"
									style={{ cursor: isClickable ? 'pointer' : 'default' }}
									// target="_blank"
									// rel="noreferrer"
								>
									<div className="media-card">
										<div className="media-image">
											<img src={item.image} alt={item.title} />
										</div>
									</div>
								</button>
							</SwiperSlide>
						))}
					</Swiper>
				</>
			) : (
				<Flex justify="space-between" gap="30px">
					{title && <h2 className="featured-title">Featured by</h2>}
					<ul className="featured-media-desktop">
						{media.map(item => (
							<li key={item.id}>
								<button
									onClick={() => onClick(item.link)}
									className="media-link"
									style={{ cursor: isClickable ? 'pointer' : 'default' }}
									// target="_blank"
									// rel="noreferrer"
								>
									<div className="media-card">
										<div className="media-image">
											<img src={item.image} alt={item.title} />
										</div>
									</div>
								</button>
							</li>
						))}
					</ul>
				</Flex>
			)}
		</>
	)
}

export default FeaturedBy
