import React from 'react'

// import useWindowSize from '../../../hooks/useWindowSize'
import './styles.scss'
import FeaturedBy from '../featured/component'

const SectionMedia = () => {
	// const { width } = useWindowSize()
	// const isMobile = useMemo(() => width < 992, [width])
	// const sliderAmount = `${media.length}`

	return (
		<section className="section section-media">
			<a className="anchor" id="featured"></a>

			<FeaturedBy title isClickable />
		</section>
	)
}

export { SectionMedia }
