import React from 'react'
import './styles.scss'
import { Box, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const SectionTimeLine = () => {
	const navigate = useNavigate()
	return (
		<div className="time">
			{/* <a className="anchor" id="products"></a> */}
			{/* <h2 className="title" style={{ color: 'white' }}>
				Our Products<a className="anchor" id="products"></a>
			</h2> */}
			<div className="block-time">
				<div className="block-year">
					<p className="time-year" style={{ fontSize: '56px' }}>
						2020
					</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" style={{ backgroundColor: '#fff' }} />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<img
								src="/images/mainPage/time-photo-ceo.png"
								className="time-photo"
								onClick={() =>
									window.open(
										'https://www.linkedin.com/in/bogdannesvit/',
										'_blank',
									)
								}
								style={{ cursor: 'pointer' }}
							/>
							<img
								src="/images/mainPage/time-photo-cto.png"
								className="time-photo"
								onClick={() =>
									window.open(
										'https://www.linkedin.com/in/anatolii-kasianov/',
										'_blank',
									)
								}
								style={{ cursor: 'pointer' }}
							/>
							<div className="block-line-text">
								<p className="time-title">HOLYWATER: HOW IT STARTED (2020)</p>
								<p className="time-text">Tested 4 business models</p>
								<p className="time-text">Assembled a team of 6 people</p>
							</div>
						</div>
						<div className="time-line">
							<ul className="time-list">
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										Launched our first Artwall app (live wallpapers and custom
										fonts)
									</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										From $1,000 to $100,000 monthly revenue in 2 months
									</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										From $100,000 to $1,000,000 in 10 months
									</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										Launched 5 more apps. 3 of them became successful
									</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										Launched web version of the successful apps
									</p>
								</Flex>
							</ul>
						</div>
						<div className="time-line">
							<img src="/images/mainPage/mf-icon.png" className="time-photo" />
							<div className="block-line-text">
								<p className="time-title">MY FANTASY (2020)</p>
								<p className="product-tag" style={{ marginBottom: 0 }}>
									INTERACTIVE STORIES GAME
								</p>
							</div>
						</div>
						<div className="time-line">
							<img
								src="/images/mainPage/passion-icon.png"
								className="time-photo"
							/>
							<div className="block-line-text">
								<p className="time-title">MY PASSION (2021)</p>
								<p className="product-tag" style={{ marginBottom: 0 }}>
									ROMANTIC BOOKS APP
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p className="time-year">2021</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" />
						<div className="horizontal-line" />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<p className="time-text">
								Closing apps business and focus on building a content ecosystem
								with My Fantasy and My Passion
							</p>
						</div>
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<p className="time-title">Full-Scale Invasion (24.02.2022)</p>
								<p className="time-text">
									Launched a charity project to raise $1 million for the needs
									of the Ukrainian army and joined the Genesis for Ukraine
									corporate charity fund
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p className="time-year">2022</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" />
						<div className="horizontal-line" />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<p className="time-text">
									Completed first successful M&A and sold the apps business.
									Found a new investor and board member
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p className="time-year">2024</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" />
						<div className="horizontal-line" />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<p
									className="time-title"
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									MY FANTASY (2020 - 2024)
									<img
										src="/images/mainPage/update-ico.png"
										width={32}
										height={32}
										style={{ marginLeft: '10px' }}
									/>
								</p>
								<p className="time-text">
									Stopped focusing on traditional interactive stories to
									reimagine the niche with AI, new media formats, and
									personalization
								</p>
							</div>
						</div>
						<div className="time-line">
							<img
								src="/images/mainPage/my-drama-ico.png"
								className="time-photo"
							/>
							<div className="block-line-text">
								<p className="time-title">MY DRAMA (2024)</p>
								<p className="product-tag" style={{ marginBottom: 0 }}>
									VERTICAL VIDEO SERIES PLATFORM
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p className="time-year">NOW</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" />
						<div className="horizontal-line" />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<p className="time-title">DEVELOPING OUR CONTENT ECOSYSTEM</p>
							</div>
						</div>
						<div className="time-line">
							<ul>
								<li className="list-item">
									<img
										src="/images/mainPage/passion-icon.png"
										className="time-photo-small"
									/>
									<p className="time-text">
										My Passion (#1 romantic books app in 10+ countries, 500%
										Y-o-Y growth)
									</p>
								</li>

								<li className="list-item">
									<img
										src="/images/mainPage/my-drama-ico.png"
										className="time-photo-small"
									/>
									<p className="time-text">
										My Drama (Vertical video series platform, #1 among European
										and American players in the niche)
									</p>
								</li>
								<li className="list-item">
									<img
										src="/images/mainPage/ai-platform.png"
										className="time-photo-small"
									/>
									<p className="time-text">
										AI Сompanion (A world where your favorite fictional
										characters come to life! With AI Сompanion, you can chat
										with beloved heroes, heroines, and villains from our books,
										series, and interactive stories)
									</p>
								</li>
								<li className="list-item">
									<img
										src="/images/mainPage/mf-icon.png"
										className="time-photo-small"
									/>
									<p className="time-text">
										Interactive Stories (Developing interactive stories games
										based on your favorite IPs)
									</p>
								</li>
							</ul>
						</div>
						<div className="time-line">
							<ul className="time-list">
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										Became worldwide. HQs in Nicosia, Porto, Warsaw, Kyiv
									</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">100+ strong team assembled</p>
								</Flex>
								<Flex alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<p className="time-text">
										20M+ installs. Used in 190+ countries. 1M+ new users monthly
									</p>
								</Flex>
							</ul>
						</div>
						<div
							className="time-line"
							onClick={() => navigate('/pyshy')}
							style={{ cursor: 'pointer' }}
						>
							<img src="/images/mainPage/pyshy.png" className="time-photo" />
							<div className="block-line-text">
								<p className="time-title">Launched the «ПИШИ» («WRITE»)</p>
								<p className="time-text">
									project – a major open call for Ukrainian authors in the
									Romance genre to promote their works worldwide
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p className="time-year" style={{ width: '210px' }}>
						2024 (soon)
					</p>
					<div className="vertical-line" style={{ marginTop: '20px' }}>
						<div className="circle" />
						<div className="horizontal-line" />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description">
						<div className="time-line">
							<img
								src="/images/mainPage/ai-platform.png"
								className="time-photo"
							/>
							<div className="block-line-text">
								<p className="time-title">
									AI COMPANION – public release and integration across all
									HOLYWATER products
								</p>
							</div>
						</div>
						<div className="time-line">
							<img
								src="/images/mainPage/audio-ico.png"
								className="time-photo"
							/>
							<div className="block-line-text">
								<p className="time-title">
									AUDIOBOOKS – reimagining how audiobooks will be consumed.
									Public release soon
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-time">
				<div className="block-year">
					<p
						className="time-year"
						style={{
							color: '#FF00C7',
							fontSize: '56px',
							fontWeight: 'bold',
							marginRight: '80px',
						}}
					>
						2026 🦄
					</p>
					<div
						className="vertical-line"
						style={{ marginTop: '20px', height: '5px' }}
					>
						<div className="circle" style={{ background: '#FF00C7' }} />
					</div>
				</div>
				<div className="block-description">
					<div className="sub-block-description" style={{ marginTop: '-15px' }}>
						<p
							className="time-title"
							style={{ color: '#FF00C7', fontSize: '56px', fontWeight: 'bold' }}
						>
							1B$ valuation
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SectionTimeLine
