import React, { useMemo } from 'react'
import './styles.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Pagination } from 'swiper'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import useWindowSize from '../../../hooks/useWindowSize'

const photo = ['1', '2', '3', '4', '5', '6', '7', '8']
const SectionHero = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<section className="hero-wrapper">
			<Swiper
				slidesPerView="auto"
				speed={1500}
				freeMode={true}
				spaceBetween={isMobile ? 0 : 20}
				loop
				className="about-slider"
				modules={[FreeMode, Pagination, Mousewheel]}
				allowSlidePrev={true}
				allowSlideNext={true}
				mousewheel={{ forceToAxis: true }}
				style={{ padding: '0 -15px', marginBottom: '110px' }}
			>
				{photo.map((item, index) => (
					<SwiperSlide
						key={item}
						className="hero-photo-item"
						style={{ width: 'auto' }}
					>
						<img
							className="hero-photo"
							src={`/images/heroSectionPhoto/${item}.png`}
							alt="team photo"
						/>
						{!isMobile && index === 4 && (
							<a
								href="mailto:info@holywater.tech"
								className="hero-button"
								style={{
									width: '180px',
									height: '180px',
									margin: 0,
									textAlign: 'start',
									padding: '36px 68px',
									position: 'relative',
								}}
							>
								Let&apos;s discuss{' '}
								<ArrowIcon
									position="absolute"
									top="65px"
									right="30px"
									w="24px"
									h="24px"
								/>
							</a>
						)}
					</SwiperSlide>
				))}
			</Swiper>

			{isMobile && (
				<a href="mailto:info@holywater.tech" className="hero-button">
					Let&apos;s discuss <ArrowIcon ml="15px" w="24px" h="24px" />
				</a>
			)}
		</section>
	)
}

export default SectionHero
