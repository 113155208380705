import {
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	IconButton,
	useDisclosure,
	Link,
} from '@chakra-ui/react'
import React from 'react'
import { Link as LinkS, animateScroll as scroll } from 'react-scroll'
import { HamburgerIcon } from '../../../shared/icons/HamburgerIcon'
import './styles.scss'
import { useLocation, useNavigate } from 'react-router-dom'

const MobileMenu = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const handleLinkClick = section => {
		if (pathname !== '/') {
			navigate('/')
			setTimeout(() => {
				const scrollY = document
					.getElementById(section)
					.getBoundingClientRect().top
				scroll.scrollTo(scrollY)
			}, 300)
		} else {
			scroll.scrollTo(section)
		}
	}
	return (
		<>
			<Button
				onClick={onOpen}
				// key={size}
				as={IconButton}
				// variant="outline"
				ml="auto"
				p="0"
				position="absolute"
				right="15px"
				top="30px"
				name="mobile menu"
				icon={<HamburgerIcon w="38px" h="40px" />}
				background="transparent"
				fill="#fff"
			/>
			<Drawer onClose={onClose} isOpen={isOpen} size="xs">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerBody background="#010101" pt="30px">
						<ul className="header-menu-mobile">
							<li>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									to="about"
									spy={true}
									smooth={true}
									duration={1000}
									onClick={() => {
										onClose()
										handleLinkClick('about')
									}}
								>
									ABOUT US
								</Link>
							</li>
							<li>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									to="products"
									spy={true}
									smooth={true}
									duration={1000}
									onClick={() => {
										onClose()
										handleLinkClick('products')
									}}
								>
									OUR PRODUCTS
								</Link>
							</li>
							<li>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									to="work"
									spy={true}
									smooth={true}
									duration={1000}
									onClick={() => {
										onClose()
										handleLinkClick('work')
									}}
								>
									WORK WITH US
								</Link>
							</li>
							<li>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									to="social"
									spy={true}
									smooth={true}
									duration={1000}
									onClick={() => {
										onClose()
										handleLinkClick('social')
									}}
								>
									SOCIAL IMPACT & FEATURES
								</Link>
							</li>
							<li>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									to="contact"
									spy={true}
									smooth={true}
									duration={1000}
									onClick={() => {
										onClose()
										handleLinkClick('contact')
									}}
								>
									CONTACT
								</Link>
							</li>
						</ul>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default MobileMenu
