import React, { useMemo } from 'react'
import {
	Flex,
	Link,
	Box,
	Text,
	Image,
	useBreakpointValue,
} from '@chakra-ui/react'
import { Link as LinkS } from 'react-scroll'
import useWindowSize from '../../../hooks/useWindowSize'

export const SectionProject = () => {
	const { width } = useWindowSize()
	const isMobile = useMemo(() => width < 833, [width])

	return (
		<section
			style={{
				padding: useBreakpointValue({
					sm: '0 15px',
					md: '0',
					lg: '0 100px',
				}),
				background: '#F3F3F4',
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<a className="anchor" id="project"></a>
			<Flex
				background="#fff"
				borderRadius="10px"
				flexDirection={useBreakpointValue({
					sm: 'column',
					md: 'row',
					lg: 'row',
				})}
				gap={useBreakpointValue({
					sm: '0px',
					md: '16px',
					lg: '74px',
				})}
				p={useBreakpointValue({
					sm: '24px 24px 32px',
					md: '30px',
					lg: '78px 50px 78px 50px',
				})}
				mt={isMobile ? '-160px' : '-120px'}
				position="relative"
				zIndex="10"
			>
				<Box
					mb="40px"
					width={useBreakpointValue({
						sm: '100%',
						md: '50%',
						lg: '55%',
					})}
				>
					<Text
						variant="pishiMedium"
						color="#03060D"
						fontSize={useBreakpointValue({
							sm: '36px',
							md: '36px',
							lg: '52px',
						})}
						mb={useBreakpointValue({
							sm: '24px',
							md: '55px',
							lg: '55px',
						})}
						lineHeight="120%"
					>
						HOLYWATER та VIVAT шукають нових зірок романтичної
						{!isMobile && <br />} літератури
					</Text>
					<Image src="/images/pishi/mob/logo.png"></Image>
				</Box>
				<Box
					width={useBreakpointValue({
						sm: '100%',
						md: '50%',
						lg: '40%',
					})}
				>
					<Text
						variant="pishiRegular"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
						mb="20px"
						color="#313133"
					>
						Неважливо, чи ти досвідчений/а автор/ка, чи тільки починаєш свій
						шлях у літературі – кожен/а може надіслати свою роботу та отримати
						ексклюзивний контракт з HOLYWATER на видавництво digital-контенту, а
						також шанс видати свою книгу в одному з найбільших видавництв
						України – Vivat!
					</Text>
					<Text
						variant="pishiRegular"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
						mb={useBreakpointValue({
							sm: '22px',
							md: '20px',
							lg: '63px',
						})}
						color="#313133"
					>
						Надсилай свої тексти у жанрі Romance і ми перетворимо їх на
						міжнародний медіапродукт — digital-книгу, інтерактивний сюжет гри,
						відеосеріал… або справжню фізичну книжку в Україні!{' '}
					</Text>
					<Box
						borderRadius="4px"
						border="1px solid #03060D"
						background="#161616"
						p="12px 0"
						textAlign="center"
						width={useBreakpointValue({
							sm: '100%',
							md: '100%',
							lg: '275px',
						})}
					>
						<Link
							display={{ md: 'block' }}
							as={LinkS}
							to="form"
							spy={true}
							smooth={true}
							duration={1000}
							fs="14px"
							color="#fff"
							width="auto"
							fontSize="18px"

							// onClick={() => {
							// 	onClose()
							// 	handleLinkClick('about')
							// }}
						>
							Надіслати свій текст
						</Link>
					</Box>
				</Box>
			</Flex>
		</section>
	)
}
