import React from 'react'
import './styles.scss'
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Image,
	List,
	ListItem,
	Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const SectionTimeLineMob = () => {
	const navigate = useNavigate()
	return (
		<Flex>
			<Box
				w="1px"
				h="auto"
				background="rgba(255,255,255,0.5)"
				mr="32px"
				pb="30px"
			/>
			<Accordion allowMultiple allowToggle width="100%">
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#fff"
						/>
						<Text
							fontSize="42px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							2020
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<Flex flexDirection="column" className="time-line">
							<Flex>
								<img
									src="/images/mainPage/time-photo-ceo.png"
									className="time-photo"
									onClick={() =>
										window.open(
											'https://www.linkedin.com/in/bogdannesvit/',
											'_blank',
										)
									}
								/>
								<img
									src="/images/mainPage/time-photo-cto.png"
									className="time-photo"
									onClick={() =>
										window.open(
											'https://www.linkedin.com/in/anatolii-kasianov/',
											'_blank',
										)
									}
								/>
							</Flex>
							<Box>
								<Text className="time-text" fontSize="24px">
									HOLYWATER: HOW IT STARTED (2020)
								</Text>
								<Text className="time-text" fontSize="18px">
									Tested 4 business models
								</Text>
								<Text className="time-text" fontSize="18px">
									Assembled a team of 6 people
								</Text>
							</Box>
						</Flex>
						<div className="time-line">
							<List className="time-list">
								<ListItem d="flex" alignItems="center">
									<Box
										width="16px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										Launched our first Artwall app (live wallpapers and custom
										fonts)
									</Text>
								</ListItem>
								<ListItem d="flex" alignItems="center">
									<Box
										width="12px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										From $1,000 to $100,000 monthly revenue in 2 months
									</Text>
								</ListItem>
								<ListItem d="flex" alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										From $100,000 to $1,000,000 in 10 months
									</Text>
								</ListItem>
								<ListItem d="flex" alignItems="center">
									<Box
										width="12px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										Launched 5 more apps. 3 of them became successful
									</Text>
								</ListItem>
								<ListItem d="flex" alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										Launched web version of the successful apps
									</Text>
								</ListItem>
							</List>
						</div>
						<Box mr="0" className="time-line">
							<Image src="/images/mainPage/mf-icon.png" w="58px" h="58px" />
							<Box mr="0" className="block-line-text">
								<Text className="time-title" fontSize="20px" mb="6px">
									MY FANTASY (2020)
								</Text>
								<Text
									className="product-tag"
									fontSize="12px"
									style={{ marginBottom: 0 }}
									padding="8px 20px"
								>
									INTERACTIVE STORIES GAME
								</Text>
							</Box>
						</Box>
						<Box mr="0" className="time-line">
							<Image
								src="/images/mainPage/passion-icon.png"
								w="58px"
								h="58px"
							/>
							<Box mr="0 !important" className="block-line-text">
								<Text className="time-title" fontSize="20px" mb="6px">
									MY PASSION (2021)
								</Text>
								<Text
									className="product-tag"
									fontSize="12px"
									style={{ marginBottom: 0 }}
									padding="8px 20px"
								>
									ROMANTIC BOOKS APP
								</Text>
							</Box>
						</Box>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#000"
						/>
						<Text
							fontSize="32px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							2021
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<div className="time-line">
							<Text className="time-text" fontSize="16px">
								Closing apps business and focus on building a content ecosystem
								with My Fantasy and My Passion
							</Text>
						</div>
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<Text className="time-title" fontSize="24px">
									Full-Scale Invasion (24.02.2022)
								</Text>
								<Text className="time-text" fontSize="16px">
									Launched a charity project to raise $1 million for the needs
									of the Ukrainian army and joined the Genesis for Ukraine
									corporate charity fund
								</Text>
							</div>
						</div>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#000"
						/>
						<Text
							fontSize="32px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							2022
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<Text className="time-text" fontSize="16px">
									Completed first successful M&A and sold the apps business.
									Found a new investor and board member
								</Text>
							</div>
						</div>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#000"
						/>
						<Text
							fontSize="32px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							2024
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<Text className="time-title" fontSize="23px" mt="5px">
									MY FANTASY (2020 - 2024)
								</Text>
								<img
									src="/images/mainPage/update-ico.png"
									width={32}
									height={32}
								/>
								<Text className="time-text" fontSize="16px">
									Stopped focusing on traditional interactive stories to
									reimagine the niche with AI, new media formats, and
									personalization
								</Text>
							</div>
						</div>
						<div className="time-line">
							<Image
								src="/images/mainPage/my-drama-ico.png"
								width={58}
								height={58}
							/>
							<div className="block-line-text">
								<Text className="time-title" fontSize="24px" mb="6px">
									MY DRAMA (2024){' '}
								</Text>
								<Text
									className="product-tag"
									fontSize="12px"
									p="5px 5px"
									style={{ marginBottom: 0 }}
								>
									VERTICAL VIDEO SERIES PLATFORM
								</Text>
							</div>
						</div>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#000"
						/>
						<Text
							fontSize="32px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							NOW
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<div className="time-line">
							<div className="block-line-text" style={{ marginLeft: 0 }}>
								<Text className="time-title" fontSize="20px">
									DEVELOPING OUR CONTENT ECOSYSTEM
								</Text>
							</div>
						</div>
						<div className="time-line">
							<ul>
								<li className="list-item">
									<img
										src="/images/mainPage/passion-icon.png"
										className="time-photo-small"
									/>
									<Text className="time-text" fontSize="16px">
										My Passion (#1 romantic books app in 10+ countries, 500%
										Y-o-Y growth)
									</Text>
								</li>

								<li className="list-item">
									<img
										src="/images/mainPage/my-drama-ico.png"
										className="time-photo-small"
									/>
									<Text className="time-text" fontSize="16px">
										My Drama (Vertical video series platform, #1 among European
										and American players in the niche)
									</Text>
								</li>
								<li className="list-item">
									<img
										src="/images/mainPage/ai-platform.png"
										className="time-photo-small"
									/>
									<Text className="time-text" fontSize="16px">
										AI Сompanion (A world where your favorite fictional
										characters come to life! With AI Сompanion, you can chat
										with beloved heroes, heroines, and villains from our books,
										series, and interactive stories)
									</Text>
								</li>
								<li className="list-item">
									<img
										src="/images/mainPage/mf-icon.png"
										className="time-photo-small"
									/>
									<Text className="time-text" fontSize="16px">
										Interactive Stories (Developing interactive stories games
										based on your favorite IPs)
									</Text>
								</li>
							</ul>
						</div>
						<div className="time-line">
							<List className="time-list">
								<ListItem d="flex" alignItems="center">
									<Box
										width="12px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										Became worldwide. HQs in Nicosia, Porto, Warsaw, Kyiv
									</Text>
								</ListItem>
								<ListItem display="flex" alignItems="center">
									<Box
										width="10px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>
									<Text className="time-text" fontSize="16px">
										100+ strong team assembled
									</Text>
								</ListItem>
								<ListItem display="flex" alignItems="center">
									<Box
										width="14px"
										height="10px"
										borderRadius="50%"
										background="white"
										mr="10px"
									/>

									<Text className="time-text" fontSize="16px">
										20M+ installs. Used in 190+ countries. 1M+ new users monthly
									</Text>
								</ListItem>
							</List>
						</div>
						<div
							className="time-line"
							style={{ alignItems: 'start', cursor: 'pointer' }}
							onClick={() => navigate('/pyshy')}
						>
							<img src="/images/mainPage/pyshy.png" width={58} height={58} />
							<div className="block-line-text">
								<Text className="time-text" fontSize="18px">
									Launched the «ПИШИ» («WRITE»)
								</Text>
								<Text className="time-text" fontSize="18px">
									project – a major open call for Ukrainian authors in the
									Romance genre to promote their works worldwide
								</Text>
							</div>
						</div>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#000"
						/>
						<Text
							fontSize="32px"
							color="#fff"
							textAlign="left"
							marginRight="auto"
						>
							2024 (soon)
						</Text>
						<AccordionIcon opacity="0.5" />
					</AccordionButton>
					<AccordionPanel p={0}>
						<div className="time-line" style={{ alignItems: 'start' }}>
							<img
								src="/images/mainPage/ai-platform.png"
								width={58}
								height={58}
							/>
							<div className="block-line-text">
								<Text className="time-title" fontSize="18px">
									AI COMPANION – public release and integration across all
									HOLYWATER products
								</Text>
							</div>
						</div>
						<div className="time-line" style={{ alignItems: 'start' }}>
							<img
								src="/images/mainPage/audio-ico.png"
								width={58}
								height={58}
							/>
							<div className="block-line-text">
								<Text className="time-title" fontSize="18px">
									AUDIOBOOKS – reimagining how audiobooks will be consumed.
									Public release soon
								</Text>
							</div>
						</div>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem border="none" borderRadius="0" background="transparent">
					<AccordionButton
						border="none"
						borderRadius="0"
						background="transparent"
						padding="0"
						position="relative"
					>
						<Box
							w="14px"
							h="14px"
							border="2px solid #808080"
							borderRadius="50%"
							left="-40px"
							position="absolute"
							background="#FF00C7"
						/>
						<Text
							fontSize="32px"
							color="#FF00C7"
							textAlign="left"
							marginRight="auto"
						>
							2026 🦄
							<br />
							1B$ valuation
						</Text>
					</AccordionButton>
				</AccordionItem>
			</Accordion>
		</Flex>
	)
}
export default SectionTimeLineMob
