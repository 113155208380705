import React from 'react'
import './styles.scss'
import FeaturedBy from '../featured/component'

const SocialSection = () => {
	return (
		<section className="section section-social">
			<h2 className="title">
				Social impact & features<a className="anchor" id="social"></a>
			</h2>
			<div className="social-wrapper">
				<p className="social-text">
					At the beginning of russia&apos;s full-scale invasion of Ukraine, we
					started selling a collection of digital artworks about the current war
					to raise $1 million for the needs of the Ukrainian army and joined the{' '}
					<a
						href="https://genesis-for-ukraine.com/"
						style={{ textDecoration: 'underline' }}
					>
						Genesis for Ukraine
					</a>{' '}
					corporate charity fund. The foundation&apos;s team purchases necessary
					equipment, vehicles, and medicines for employees and their families
					who are defending the country on the front line.
				</p>
				<FeaturedBy isClickable />
			</div>
		</section>
	)
}

export default SocialSection
