import React, { useMemo } from 'react'
import { FixedHeader } from '../../components/common/fixedHeader'
import { SectionAbout } from '../../components/mainPage/sectionAbout'
import { SectionMedia } from '../../components/mainPage/sectionMedia'
import { MainFooter } from '../../components/common/mainFooter'
import './styles.scss'
import SectionHero from '../../components/mainPage/sectionHero/component'
import SectionProduct from '../../components/mainPage/sectionProduct/component'
import SectionWork from '../../components/mainPage/sectionWork/component'
import SocialSection from '../../components/mainPage/sectionSocial/component'
import useWindowSize from '../../hooks/useWindowSize'
import MobileMenu from '../../components/common/mobileMenu/component'

const MainNewPage = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<div className="page-container">
			<header className="page-header">
				{isMobile ? (
					<MobileMenu />
				) : (
					<FixedHeader products about featured contact />
				)}
				<div className="header-promo">
					<p className="promo-text">
						We develop and publish books, audiobooks, interactive stories, and
						video series.
					</p>
					<h1 className="promo-holy">Holywater</h1>
					<h2 className="promo-title">
						is&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
						{!isMobile && (
							<>
								&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{' '}
							</>
						)}{' '}
						a media-tech
						{!isMobile && <br />} company that builds per
						{isMobile && <br />}
						sonalized worlds{isMobile && <>&ensp;&ensp;&ensp;&ensp;</>} for 20+{' '}
						{isMobile && (
							<>
								&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
							</>
						)}
						million users by combining
						{isMobile && <br />} creators
						{isMobile && <>&apos;&ensp;&ensp;&ensp;</>} imagination with
						AI&apos;s efficiency.
					</h2>
				</div>
			</header>
			<main className="page-main">
				<SectionHero />
				<SectionMedia />
				<SectionAbout />
				<SectionProduct />
				<SectionWork />
				<SocialSection />
			</main>
			<MainFooter />
		</div>
	)
}

export { MainNewPage }
