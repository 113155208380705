import React, { useMemo } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { FixedHeader } from '../../components/common/fixedHeader'
import MobileMenu from '../../components/common/mobileMenu/component'
import './styles.scss'
import { Link } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link as LinkS, animateScroll as scroll } from 'react-scroll'

export const Sitemap = () => {
	const { width } = useWindowSize()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const isMobile = useMemo(() => width < 768, [width])
	const handleLinkClick = section => {
		if (pathname !== '/') {
			navigate('/')
			console.log('section', section)
			setTimeout(() => {
				const scrollY = document
					.getElementById(section)
					.getBoundingClientRect().top
				scroll.scrollTo(scrollY - 100)
			}, 300)
		} else {
			scroll.scrollTo(section)
		}
	}
	return (
		<>
			{isMobile ? (
				<MobileMenu />
			) : (
				<FixedHeader products about featured contact />
			)}
			<div className="wrapper-sitemap">
				<h2 className="not-found-title">Home page</h2>
				<ul className="header-menu">
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="about"
							spy={true}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('about')}
						>
							ABOUT US
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="products"
							spy={true}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('products')}
						>
							OUR PRODUCTS
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="work"
							spy={true}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('work')}
						>
							WORK WITH US
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="social"
							spy={true}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('social')}
						>
							SOCIAL IMPACT & FEATURES
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="contact"
							spy={true}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('contact')}
						>
							CONTACT
						</Link>
					</li>
				</ul>
			</div>
		</>
	)
}
