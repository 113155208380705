/* eslint-disable react/no-unknown-property */
// import { LinkBox, LinkOverlay } from '@chakra-ui/react'
import React, { useMemo } from 'react'
// import { FreeMode, Pagination } from 'swiper'
// import Video from '../../teamPage/videoSlide/video/team-video.mp4'
// import { members } from './data'
import './styles.scss'
import { HeartIcon } from '../../../shared/icons/HeartIcon'
import { LocationIcon } from '../../../shared/icons/LocationIcon'
import { UsersIcon } from '../../../shared/icons/UsersIcon'
import { Box, Flex, Switch } from '@chakra-ui/react'
import useWindowSize from '../../../hooks/useWindowSize'
import SectionTimeLine from '../sectionTimeLine/component'
import SectionTimeLineMob from '../sectionTimeLineMob/component'
// import './styles.css'
// import { Text } from '@chakra-ui/react'

const aboutData = [
	{
		icon: (
			<div className="icon-wrapper">
				<HeartIcon w="43px" h="40px" />
			</div>
		),
		text: (
			<p className="about-list-text">
				Our products have been installed{' '}
				<span className="text-accent">20M+</span> times.
			</p>
		),
	},
	{
		icon: (
			<div className="icon-wrapper">
				<LocationIcon w="36px" h="40px" />
			</div>
		),
		text: (
			<p className="about-list-text">
				Our products are used in <span className="text-accent">190+</span>{' '}
				countries
			</p>
		),
	},
	{
		icon: (
			<div className="icon-wrapper">
				<UsersIcon w="42px" h="40px" />
			</div>
		),
		text: (
			<p className="about-list-text">
				<span className="text-accent">1M+</span> new users join monthly.
			</p>
		),
	},
]

const SectionAbout = () => {
	const [check, setCheck] = React.useState(true)
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<>
			<section className="section section-about">
				<div
					className="section-block-wrapper"
					style={{ paddingTop: 0, display: 'block' }}
				>
					<div className="wrapper-about">
						<div className="wrapper-text-desc">
							<a className="anchor" id="about"></a>
							<div className="section-title">
								<h2 className="title">About us</h2>
							</div>
						</div>
						<div className="wrapper-text-desc">
							<h2 className="subtitle-about">
								HOLYWATER was founded in early 2020 in Kyiv, Ukraine.
							</h2>
							<div className="about-info">
								<h3 className="about-text">
									Since then we&apos;ve managed to launch our trendsetting
									products which regularly appear in top charts and assembled a
									strong team of 100+ people.
								</h3>
							</div>
							<ul className="about-list">
								{aboutData.map(({ icon, text }, index) => (
									<li key={text} className="about-item">
										{icon}
										{text}
									</li>
								))}
							</ul>
						</div>
					</div>
					{isMobile ? <SectionTimeLineMob /> : <SectionTimeLine />}
				</div>
				<div className="section-block-wrapper">
					<div className="wrapper-text-desc">
						<h2 className="title">So why HOLYWATER?</h2>
					</div>
					<div className="wrapper-text-desc">
						<h2 className="main-title">Holywater</h2>
						<div className="wrapper">
							<div className="holy-text-wrapper">
								{/* <h2 className="main-title">Holy</h2> */}
								<div className="border-holy" />
								<p className="about-text fs-16 center-text">
									is {isMobile && <>&ensp;&ensp;</>}a {isMobile && <>&ensp;</>}
									community united {isMobile && <>&ensp;&ensp;</>}by
									{isMobile && <>&ensp;&ensp;</>} the same values and goals.
								</p>
							</div>

							<div className="water-text-wrapper">
								{/* <h2 className="main-title">water</h2> */}
								<div className="border-water" />
								<p className="about-text fs-16">
									is the strongest element that combines two opposite things:
									persistence and flexibility.
								</p>
							</div>
						</div>
						<p className="second-text width-50">
							<span className="text-accent">TOGETHER</span> this creates
							the&ensp;&ensp;&ensp;&ensp; strongest community&ensp;&ensp;&ensp;
							in&ensp;&ensp; the media-tech industry —{' '}
							<span className="text-accent">HOLYWATER.</span>
						</p>
					</div>
					{/* </div> */}
				</div>
				<div className="section-block-wrapper mission-text">
					<h2 className="title">Our mission</h2>
					<div className="mission-text-wrapper">
						<p className="second-text text-section-about">
							We&ensp;&ensp;&ensp;{!isMobile && <>&ensp;</>}
							<Switch
								size="lg"
								mt={isMobile ? '-55px' : '0'}
								isChecked={check}
								onChange={() => setCheck(!check)}
								aria-label="switch"
								sx={{
									'& .chakra-switch__track': {
										width: '100px',
										height: '50px',
										_focus: {
											outline: 'none',
											boxShadow: 'none',
										},
									},
									'& .chakra-switch span': {
										width: '100px',
										height: '50px',
									},
									'& .chakra-switch__thumb': {
										height: '44px',
										width: '44px',
										margin: '3px',
										_checked: {
											transform: 'translateX(53px)',
										},
									},
									_checked: {
										'& .chakra-switch__track': {
											backgroundColor: '#ff00c7',
										},
									},
								}}
							/>
							&ensp;&ensp;&ensp; unlock people&apos;s potential.
						</p>
						<Box
							className="img-unlock"
							w="170px"
							h="170px"
							backgroundColor="#131313"
							borderRadius="32px"
							textAlign="center"
							p="20px"
							mt={isMobile ? '-20px' : 0}
						></Box>
					</div>
				</div>
				<div className="section-block-wrapper">
					<h2 className="title">Our vision</h2>
					<div className="mission-text-wrapper width-60">
						<p className="second-text text-section-about width-60">
							To become the leading media-tech company in the world by creating
							personal&ensp;&ensp; stories&ensp;&ensp; for everyone.
						</p>
						<img
							src="images/mainPage/vision.png"
							className="img-vision"
							alt="vision-icon"
						/>
					</div>
				</div>
				<div
					className="section-block-wrapper enemy-block"
					style={{ borderBottom: 'none', paddingBottom: '0' }}
				>
					<h2 className="title">Our enemy</h2>
					<div className="mission-text-wrapper text-section-about-enemy">
						<p
							className="second-text text-section-about-enemy"
							style={{
								marginBottom: '24px',
							}}
						>
							Status&ensp;&ensp; quo — if&ensp;&ensp; you
							don&apos;t&ensp;evolve,&ensp;&ensp;&ensp; you become history.
						</p>
						<p className="about-text fs-16" style={{ marginBottom: 0 }}>
							We act fast, think bold, and use the latest tech to stay in the
							game.
						</p>
					</div>
				</div>
			</section>
		</>
	)
}

export { SectionAbout }
