import React, { useMemo } from 'react'
import {
	Box,
	Text,
	Flex,
	Link,
	Image,
	Grid,
	GridItem,
	useBreakpointValue,
} from '@chakra-ui/react'
import { Link as LinkS } from 'react-scroll'
import { PhoneIcon } from '../../../shared/icons/PhoneIcon'
import { InfiniteIcon } from '../../../shared/icons/InfiniteIcon'
import { LangIcon } from '../../../shared/icons/LangIcon'
import useWindowSize from '../../../hooks/useWindowSize'

const PRODUCTS = {
	drama: {
		link: 'https://play.google.com/store/apps/details?id=com.dashdramas&referrer=af_tranid%3DsM2_YAz-4oNKxGxawIyOfw',
		linkIOS:
			'https://apps.apple.com/us/app/my-drama-amazing-episodes/id6498713494',
	},
	passion: {
		link: 'https://play.google.com/store/apps/details?id=gm.passion.app&hl=en',
		linkIOS:
			'https://apps.apple.com/us/app/passion-romance-fiction-books/id1568149521',
	},
	mf: {
		// link: 'https://play.google.com/store/apps/details?id=gmem.episode&hl=en',
		// linkIOS:
		// 	'https://apps.apple.com/mm/app/my-fantasy-choose-your-story/id1491717191',
	},
}

const SectionStage = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 834, [width])
	const handleClick = app => {
		const isAndroid = navigator.userAgent.match(/Android/i)
		if (PRODUCTS[app].link) {
			window.open(
				isAndroid ? PRODUCTS[app].link : PRODUCTS[app].linkIOS,
				'_blank',
			)
		}
	}
	return (
		<section
			style={{
				background: '#F3F3F4',
				padding: useBreakpointValue({
					sm: '0 15px',
					md: '0',
					lg: '0 100px',
				}),
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<Box
				p={useBreakpointValue({
					sm: '120px 0',
					md: '90px 0',
					lg: '92px 0',
				})}
			>
				<a className="anchor" id="stage"></a>
				<Box textAlign="center">
					<Text
						variant="pishiRegular"
						p="6px 12px 8px"
						color="#D04B6E"
						borderRadius="10px"
						border="1px solid #D04B6E"
						display="inline-block"
						mb="14px"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
					>
						Етап 1
					</Text>
					<Text
						variant="pishiMedium"
						color="#03060D"
						mb="42px"
						fontSize={useBreakpointValue({
							sm: '40px',
							md: '40px',
							lg: '52px',
						})}
					>
						Ти і книга
					</Text>
				</Box>
				<Flex
					flexDirection={useBreakpointValue({
						sm: 'column',
						md: 'row',
						lg: 'row',
					})}
					borderRadius="10px"
					background="#fff"
					border="1px solid #fff"
					p={useBreakpointValue({
						sm: '0',
						md: '0',
						lg: '0',
					})}
					gap="50px"
				>
					<Box
						p={useBreakpointValue({
							sm: '25px 24px 0',
							md: '56px 0 0 30px',
							lg: '60px 0 0 60px',
						})}
						width={useBreakpointValue({
							sm: '100%',
							md: '50%',
							lg: '50%',
						})}
					>
						<Text
							variant="pishiMedium"
							fontSize={useBreakpointValue({
								sm: '28px',
								md: '28px',
								lg: '40px',
							})}
							color="#03060D"
							m={useBreakpointValue({
								sm: '0 -2px 24px',
								md: '0 0 40px 0',
								lg: '0 0 60px 0',
							})}
						>
							Надсилай свій готовий текст до 31 серпня, або створи новий до
							кінця вересня і відправ нам!
						</Text>

						<Link
							display={{ md: 'block' }}
							as={LinkS}
							to="form"
							spy={true}
							smooth={true}
							duration={1000}
							color="#D04B6E"
							width="100%"
							fontFamily="Inter-Medium"
							fontweight="500"
							fontSize="20px"
							p="12px 0"
						>
							Надіслати свій текст →
						</Link>
					</Box>
					<Box
						width={useBreakpointValue({
							sm: '100%',
							md: '50%',
							lg: '50%',
						})}
					>
						<Image
							src={
								isMobile
									? '/images/pishi/mob/you_book.png'
									: '/images/pishi/desc/you_book.png'
							}
							width="100%"
							display="block"
							objectFit="contain"
							borderBottomRadius="10px"
						></Image>
					</Box>
				</Flex>
			</Box>
			<Flex flexDirection="column" variant="pishiMedium" fontSize="40px">
				<Text
					variant="textTitle"
					textAlign="center"
					mb={useBreakpointValue({
						sm: '40px',
						md: '40px',
						lg: '52px',
					})}
				>
					Необхідні умови
				</Text>
				<Grid
					gap="14px"
					templateRows={useBreakpointValue({
						sm: '1fr',
						md: 'repeat(2, 1fr)',
						lg: 'repeat(2, 1fr)',
					})}
					templateColumns={useBreakpointValue({
						sm: '1fr',
						md: 'repeat(3, 1fr)',
						lg: 'repeat(3, 1fr)',
					})}
					maxWidth="1240px"
					justifyContent="center"
					m="auto"
				>
					<GridItem
						background="#fff"
						borderRadius="10px"
						p={useBreakpointValue({
							sm: '63px 48px',
							md: '90px 36px',
							lg: '75px 62px 75px 45px',
						})}
						colSpan={isMobile ? 1 : 1}
						maxWidth="404px"
					>
						<Flex
							justifyContent={useBreakpointValue({
								sm: 'center',
								md: 'end',
								lg: 'end',
							})}
							mb="16px"
							alignItems="center"
						>
							<Text
								variant="textBadge"
								background="#DF7A9E"
								color="#fff"
								mr="8px"
								border={null}
								display="flex"
								alignItems="center"
								maxH="39px"
							>
								En
							</Text>
							<Text
								variant="textBadge"
								mr="20px"
								background="#DF7A9E"
								color="#fff"
								border={null}
								display="flex"
								alignItems="center"
								maxH="39px"
							>
								Ua
							</Text>
							<Text variant="textTitle">Mова</Text>
						</Flex>
						<Text
							variant="pishiRegular"
							color="#03060D"
							fontSize="16px"
							textAlign={isMobile ? 'center' : 'end'}
						>
							Твій текст може бути українською або англійською мовами
						</Text>
					</GridItem>
					<GridItem
						background="#fff"
						borderRadius="10px"
						p={useBreakpointValue({
							sm: '45px 0px',
							md: '30px 50px',
							lg: '52px 34px',
						})}
						colSpan={useBreakpointValue({
							sm: '1',
							md: '2',
							lg: '2',
						})}
						maxWidth="822px"
						// width={isMobile ? '100%' : '70%'}
					>
						<Text variant="textTitle" textAlign="center" mb="16px">
							Сюжети
						</Text>
						<Flex flexWrap="wrap" justifyContent="center" gap="8px">
							<Text variant="textBadge" border="1px solid #03060D">
								Alpha Male
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Billionaire Romance
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Secret Billionaire
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Werewolf Romance
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Mafia Romance
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Hate to Love
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Kick-ass Heroine
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Forced Marriage
							</Text>
						</Flex>
					</GridItem>
					<GridItem
						background="#fff"
						borderRadius="10px"
						p={useBreakpointValue({
							sm: '63px 48px',
							md: '63px 36px',
							lg: '52px 161px',
						})}
						colSpan={useBreakpointValue({
							sm: '1',
							md: '2',
							lg: '2',
						})}
						maxWidth="822px"
						alignItems="center"
					>
						<Text variant="textTitle" textAlign="center" mb="16px">
							Жанри
						</Text>
						<Flex flexWrap="wrap" justifyContent="center" gap="8px">
							<Text variant="textBadge" border="1px solid #03060D">
								Alpha Romance
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Paranormal Romance
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Romantic Suspense
							</Text>
							<Text variant="textBadge" border="1px solid #03060D">
								Fantasy Romance
							</Text>
						</Flex>
					</GridItem>
					<GridItem
						background="#fff"
						borderRadius="10px"
						p={useBreakpointValue({
							sm: '63px 48px',
							md: '82px 42px 82px 14px',
							lg: '63px 48px',
						})}
						colSpan={isMobile ? 1 : 1}
						maxWidth="404px"
					>
						<Flex
							mb="16px"
							justifyContent={isMobile ? 'center' : 'start'}
							alignItems="center"
						>
							<Text
								variant="textTitle"
								mr="20px"
								textAlign={isMobile ? 'center' : 'start'}
							>
								Обсяг
							</Text>
							<Text
								variant="textBadge"
								background="#DF7A9E"
								color="#fff"
								border={null}
								mr="8px"
								display="flex"
								alignItems="center"
								maxH="39px"
							>
								280k
							</Text>
							<Text
								variant="textBadge"
								background="#DF7A9E"
								color="#fff"
								border={null}
								display="flex"
								alignItems="center"
								maxH="39px"
							>
								{'>'}
							</Text>
						</Flex>
						<Text
							variant="pishiRegular"
							color="#03060D"
							fontSize="16px"
							textAlign={isMobile ? 'center' : 'start'}
						>
							Обʼєм твоєї книги має бути{!isMobile && <br />} від 280 000 знаків
							із пробілами
						</Text>
					</GridItem>
				</Grid>
			</Flex>
			<Box p={isMobile ? '120px 0 0' : '120px 0 0'}>
				<Box textAlign="center">
					<Text
						variant="pishiRegular"
						p="6px 12px 8px"
						color="#D04B6E"
						borderRadius="10px"
						border="1px solid #D04B6E"
						display="inline-block"
						mb="14px"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
					>
						Етап 2
					</Text>
					<Text
						variant="pishiMedium"
						color="#03060D"
						mb="42px"
						fontSize={useBreakpointValue({
							sm: '40px',
							md: '40px',
							lg: '52px',
						})}
					>
						Ти і HOLYWATER
					</Text>
				</Box>
				<Flex
					flexDirection={useBreakpointValue({
						sm: 'column',
						md: 'row',
						lg: 'row',
					})}
					borderRadius="10px"
					background="#fff"
					border="1px solid #fff"
					p={useBreakpointValue({
						sm: '55px 0 0',
						md: '0',
						lg: '0',
					})}
					gap={useBreakpointValue({
						sm: '50px',
						md: '0',
						lg: '50px',
					})}
				>
					<Box
						p={useBreakpointValue({
							sm: '0 24px 0',
							md: '62px 29px 28px 30px',
							lg: '128px 0 0 60px',
						})}
						width={useBreakpointValue({
							sm: '100%',
							md: '50%',
							lg: '50%',
						})}
					>
						<Text
							variant="pishiMedium"
							fontSize={useBreakpointValue({
								sm: '28px',
								md: '28px',
								lg: '40px',
							})}
							color="#03060D"
							m={useBreakpointValue({
								sm: '0 -2px 24px',
								md: '0 0 12px 0',
								lg: '0 0 60px 0',
							})}
						>
							Міжнародний бестселер
						</Text>
						<Text
							variant="pishiRegular"
							fontSize="16px"
							color="#03060D"
							m="0 0 24px"
							letterSpacing="-0.5px"
						>
							HOLYWATER відбирає твори, які відповідають вимогам конкурсу,
							адаптує та тестує їх серед реальних користувачів наших платформ,
							збираючи аналітику для розуміння потенційної популярності ваших
							текстів.
						</Text>
						<Link
							display={{ md: 'block' }}
							as={LinkS}
							to="form"
							spy={true}
							smooth={true}
							duration={1000}
							color="#D04B6E"
							width="100%"
							fontSize="20px"
							p="12px 0"
							fontFamily="Inter-Medium"
							fontweight="500"
							// onClick={() => {
							// 	onClose()
							// 	handleLinkClick('about')
							// }}
						>
							Надіслати свій текст →
						</Link>
					</Box>
					<Box
						width={useBreakpointValue({
							sm: '100%',
							md: '50%',
							lg: '50%',
						})}
					>
						<Image
							src={useBreakpointValue({
								sm: '/images/pishi/mob/you_hw.png',
								md: '/images/pishi/desc/you_hw.png',
								lg: '/images/pishi/desc/you_hw.png',
							})}
							width="100%"
							display="block"
							objectFit="contain"
							borderBottomRadius="10px"
						></Image>
					</Box>
				</Flex>
			</Box>
			<Box
				p={useBreakpointValue({
					sm: '120px 0 ',
					md: '160px 0 160px 0',
					lg: '160px 0 160px 0',
				})}
			>
				<Box textAlign="center">
					<Text
						variant="pishiRegular"
						p="6px 12px 8px"
						color="#D04B6E"
						borderRadius="10px"
						border="1px solid #D04B6E"
						display="inline-block"
						mb="14px"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
					>
						Переможець/ниця отримає
					</Text>
					<Text
						variant="pishiMedium"
						color="#03060D"
						mb="14px"
						fontSize={useBreakpointValue({
							sm: '40px',
							md: '40px',
							lg: '52px',
						})}
						letterSpacing="-0.5px"
					>
						30 000₴ та контракт
					</Text>
					<Text
						variant="pishiRegular"
						fontSize={useBreakpointValue({
							sm: '16px',
							md: '16px',
							lg: '18px',
						})}
						color="#03060D"
						m="0 0 42px"
						letterSpacing="-0.5px"
					>
						Автори/ки творів отримають по 30 000 гривень гонорару та
						ексклюзивний контракт з{!isMobile && <br />} медіакомпанією
						HOLYWATER. Їхні роботи будуть опубліковані в додатку My Passion –
						{!isMobile && <br />}
						найпопулярнішій цифровій книжковій бібліотеці у жанрі Romance від
						HOLYWATER.
					</Text>
				</Box>
				<Grid
					gap="14px"
					templateRows={useBreakpointValue({
						sm: '7fr',
						md: 'repeat(3, 1fr)',
						lg: 'repeat(3, 1fr)',
					})}
					templateColumns={useBreakpointValue({
						sm: '1fr',
						md: 'repeat(6, 1fr)',
						lg: 'repeat(6, 1fr)',
					})}
				>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '3',
							lg: '4',
						})}
					>
						<Flex
							flexDirection="column"
							borderRadius="10px"
							background="#fff"
							border="1px solid #fff"
							// p="40px 0 0"
							gap="50px"
						>
							<Box
								p={useBreakpointValue({
									sm: '55px 24px 0',
									md: '25px 30px 20px',
									lg: '86px 60px',
								})}
								height="100%"
							>
								<Flex>
									<Text
										variant="pishiMedium"
										color="#03060D"
										fontSize={useBreakpointValue({
											sm: '28px',
											md: '28px',
											lg: '40px',
										})}
										mb={useBreakpointValue({
											sm: '12px',
											md: '14px',
											lg: '22px',
										})}
									>
										Колаборація з нашими застосунками
									</Text>
									<PhoneIcon
										w="40px"
										h="38px"
										ml="auto"
										stroke="#fff"
										fill="transparent"
										background="#DF7A9E"
										p="8px"
										borderRadius="10px"
									/>
								</Flex>
								<Text
									variant="pishiRegular"
									fontSize="16px"
									color="#03060D"
									m={useBreakpointValue({
										sm: '0 0 24px',
										md: '0',
										lg: '0',
									})}
									letterSpacing="-0.5px"
								>
									Якщо твоя книга стане популярною на платформі My Passion, ми
									зможемо разом перетворити її на аудіокнигу, сюжет
									інтерактивної гри та сценарій для відеосеріалу в межах
									екосистеми застосунків HOLYWATER. Переможці/ниці
									отримуватимуть щомісячні роялті від усіх продажів книги та
									інших продуктів на основі її сюжету
								</Text>
							</Box>
						</Flex>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '3',
							lg: '2',
						})}
					>
						<Box>
							<Image
								src="/images/pishi/mob/you_passion.png"
								display="block"
								objectFit="contain"
								width="100%"
								borderBottomRadius="10px"
							></Image>
						</Box>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '2',
							lg: '2',
						})}
					>
						<Box
							p={useBreakpointValue({
								sm: '40px 24px',
								md: '30px 24px',
								lg: '65px 50px',
							})}
							borderRadius="10px"
							height="100%"
							background="linear-gradient(199deg, #FFF4FD 13.83%, #F5E2F3 109.81%)"
							cursor="pointer"
							onClick={() => handleClick('passion')}
						>
							<Image
								src="/images/pishi/mob/passion_icon.png"
								display="block"
								objectFit="contain"
								width="132px"
								height="132px"
								ml="auto"
								mb="32px"
							></Image>
							<Text
								variant="pishiMedium"
								color="#03060D"
								fontSize={useBreakpointValue({
									sm: '28px',
									md: '28px',
									lg: '40px',
								})}
								mb="14px"
							>
								My Passion
							</Text>
							<Text variant="pishiMedium" fontSize="16px" color="#313133">
								книжкова бібліотека у жанрі Romance — перше місце за кількістю
								завантажень App Store у 10+ країнах світу
							</Text>
						</Box>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '2',
							lg: '2',
						})}
					>
						<Box
							p={useBreakpointValue({
								sm: '40px 24px',
								md: '30px 24px',
								lg: '65px 50px',
							})}
							borderRadius="10px"
							height="100%"
							background="linear-gradient(199deg, #E9EAFE 13.83%, #E2E3F9 109.81%)"
							cursor="pointer"
							onClick={() => handleClick('drama')}
						>
							<Image
								src="/images/pishi/mob/drama_icon.png"
								display="block"
								objectFit="contain"
								width="132px"
								height="132px"
								ml="auto"
								mb="32px"
							></Image>
							<Text
								variant="pishiMedium"
								color="#03060D"
								fontSize={useBreakpointValue({
									sm: '28px',
									md: '28px',
									lg: '40px',
								})}
								mb="14px"
							>
								My Drama
							</Text>
							<Text variant="pishiMedium" fontSize="16px" color="#313133">
								відеотека серіалів, створених за мотивами наших книжок та
								інтерактивних історій
							</Text>
						</Box>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '2',
							lg: '2',
						})}
					>
						<Box
							p={useBreakpointValue({
								sm: '40px 24px',
								md: '30px 24px',
								lg: '70px 40px 55px 20px',
							})}
							borderRadius="10px"
							height="100%"
							background="linear-gradient(199deg, #FAECFF 13.83%, #EFE0F6 109.81%)"
						>
							<Image
								src="/images/pishi/mob/is_icon.png"
								display="block"
								objectFit="contain"
								width="132px"
								height="132px"
								borderRadius="30px"
								ml="auto"
								mb="32px"
							></Image>
							<Text
								variant="pishiMedium"
								color="#03060D"
								fontSize={useBreakpointValue({
									sm: '28px',
									md: '28px',
									lg: '30px',
								})}
								mb="14px"
							>
								Interactive Stories
							</Text>
							<Text variant="pishiMedium" fontSize="16px" color="#313133">
								мобільна гра у форматі інтерактивних історій — перше місце у
								світі за кількістю завантажень на Android у своїй ніші
							</Text>
						</Box>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '3',
							lg: '3',
						})}
					>
						<Flex
							flexDirection="column"
							borderRadius="10px"
							background="#fff"
							border="1px solid #fff"
							// p="40px 0 0"
							gap="50px"
							mb="14px"
							height="100%"
						>
							<Box
								p={useBreakpointValue({
									sm: '55px 24px 0',
									md: '30px 24px',
									lg: '55px 24px 0',
								})}
							>
								<Flex
									mb={useBreakpointValue({
										sm: '0',
										md: '0',
										lg: '60px',
									})}
									gap={useBreakpointValue({
										sm: '0',
										md: '34px',
										lg: '0',
									})}
								>
									<Text
										variant="pishiMedium"
										color="#03060D"
										fontSize={useBreakpointValue({
											sm: '28px',
											md: '28px',
											lg: '40px',
										})}
										mb="12px"
									>
										Необмежена кількість контрактів на публікацію!
									</Text>
									<InfiniteIcon
										w="40px"
										h="38px"
										ml="auto"
										stroke="#fff"
										strokeWidth="0"
										fill="transparent"
										background="#DF7A9E"
										p="8px"
										borderRadius="10px"
									/>
								</Flex>
								<Text
									variant="pishiRegular"
									fontSize="16px"
									color="#03060D"
									m={useBreakpointValue({
										sm: '0 0 24px',
										md: '0',
										lg: '0 0 24px',
									})}
									letterSpacing="-0.5px"
								>
									Контрактів на співпрацю й розміщення книг на наших платформах
									— необмежена кількість! Кожен/на автор/ка, твір якого/ї пройде
									випробування читачами застосунку My Passion - має шанс
									отримати запрошення до співпраці з HOLYWATER!
								</Text>
							</Box>
						</Flex>
					</GridItem>
					<GridItem
						colSpan={useBreakpointValue({
							sm: '1',
							md: '3',
							lg: '3',
						})}
					>
						<Flex
							flexDirection="column"
							borderRadius="10px"
							background="#fff"
							border="1px solid #fff"
							// p="40px 0 0"
							gap="50px"
							mb={useBreakpointValue({
								sm: '14px',
								md: '0',
								lg: '14px',
							})}
							height="100%"
						>
							<Box
								p={useBreakpointValue({
									sm: '55px 24px 0',
									md: '30px 24px',
									lg: '55px 24px 0',
								})}
							>
								<Flex
								// gap={useBreakpointValue({
								// 	sm: '0',
								// 	md: '7px',
								// 	lg: '0',
								// })}
								>
									<Text
										variant="pishiMedium"
										color="#03060D"
										fontSize={useBreakpointValue({
											sm: '28px',
											md: '28px',
											lg: '35px',
										})}
										mb="12px"
									>
										Промоція твого твору на міжнародному ринку від HOLYWATER
									</Text>
									<LangIcon
										w="40px"
										h="38px"
										ml="auto"
										stroke="#fff"
										strokeWidth="1"
										fill="transparent"
										background="#DF7A9E"
										p="8px"
										borderRadius="10px"
									/>
								</Flex>
								<Text
									variant="pishiRegular"
									fontSize="16px"
									color="#03060D"
									m={useBreakpointValue({
										sm: '0 0 24px',
										md: '0',
										lg: '0 0 24px',
									})}
									letterSpacing="-0.5px"
								>
									HOLYWATER володіє потужними маркетинговими інструментами, має
									великий досвід у промоції електронних книг та успішно
									співпрацює з авторами/ками по всьому світу протягом багатьох
									років. <br />
									<br />
									Завдяки цьому компанія забезпечує ефективну підтримку та
									просування творів, допомагаючи авторам/кам досягати широкої
									аудиторії та значних успіхів.
								</Text>
							</Box>
						</Flex>
					</GridItem>
				</Grid>

				<Box
					textAlign="center"
					p={useBreakpointValue({
						sm: '120px 0 0',
						md: '120px 0 0',
						lg: '160px 0 0',
					})}
				>
					<Text
						variant="pishiRegular"
						p="6px 12px 8px"
						color="#D04B6E"
						borderRadius="10px"
						border="1px solid #D04B6E"
						display="inline-block"
						mb="14px"
						fontSize="16px"
					>
						Суперприз
					</Text>
					<Text
						variant="pishiMedium"
						color="#03060D"
						mb="14px"
						fontSize={useBreakpointValue({
							sm: '40px',
							md: '40px',
							lg: '52px',
						})}
						letterSpacing="-0.5px"
					>
						Ти + HOLYWATER + VIVAT
					</Text>
					<Text
						variant="pishiRegular"
						fontSize={useBreakpointValue({
							sm: '14px',
							md: '16px',
							lg: '18px',
						})}
						color="#03060D"
						m="0 0 42px"
						letterSpacing="-0.5px"
					>
						Фіналістів/ок очікує суперприз! Автор/ка найкращого твору отримає
						можливість надрукувати{!isMobile && <br />} свій текст в одному з
						найбільших видавництв України – Vivat, а також отримати ексклюзивний
						{!isMobile && <br />}
						контракт на промо та просування своєї книги на українському ринку!
					</Text>
				</Box>
				<Flex mb="42px" justifyContent="center">
					<Image
						src={
							isMobile
								? '/images/pishi/mob/you_hw_vivat.png'
								: '/images/pishi/desc/you_hw_vivat.png'
						}
						// display="block"
						objectFit="contain"
						borderBottomRadius="10px"
					></Image>
				</Flex>
				<Text
					variant="pishiRegular"
					fontSize="14px"
					color="#03060D"
					m={useBreakpointValue({
						sm: '0 0 42px',
						md: '0',
						lg: '0',
					})}
					letterSpacing="-0.5px"
					textAlign="center"
				>
					HOLYWATER і Vivat залишають за собою право не обирати переможця/ницю
					та не підписувати з ним/нею контракт{!isMobile && <br />} за умови, що
					жодний з творів-учасників не відповідатиме вимогам конкурсу і компаній
				</Text>
			</Box>
		</section>
	)
}

export default SectionStage
