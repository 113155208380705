import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const ArrowIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M19 1.5C19 0.947714 18.5523 0.499999 18 0.499999L9 0.5C8.44772 0.499999 8 0.947715 8 1.5C8 2.05228 8.44772 2.5 9 2.5L17 2.5L17 10.5C17 11.0523 17.4477 11.5 18 11.5C18.5523 11.5 19 11.0523 19 10.5L19 1.5ZM1.70711 19.2071L18.7071 2.20711L17.2929 0.792893L0.292893 17.7929L1.70711 19.2071Z"
				fill="white"
			/>
		</>
	),
})
